import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
                                                                                                                                
export enum NotificationLevel
{
    Info,
    Success,
    Warning,
    Error
}

@Injectable()
export class MessageNotifier
{
    //--------------------------------------------------------------------------------------------
    // CONSTRUCTOR
    //--------------------------------------------------------------------------------------------
    constructor(
        private _snackBar: MatSnackBar,
        private _translateService: TranslateService
    )
    {
    }

    //--------------------------------------------------------------------------------------------
    // PUBLIC METHODS
    //--------------------------------------------------------------------------------------------
    public displayMessage(message: string, level: NotificationLevel, autoHide: boolean, ...params: string[])
    {
        let config = new MatSnackBarConfig();
        if (autoHide)
        {
            config.duration = DURATION_MESSAGE;
        }
        //config.extraClasses = ["snack-bar", MAP_LEVEL_TO_CLASS[level]];
        this._snackBar.open(this._translateService.instant(message).format(params), this._translateService.instant('LABEL_HIDE'), config);
    }
}

const MAP_LEVEL_TO_CLASS: { [level: number]: string } =
    {
        [NotificationLevel.Info]:     'snack-bar-info',
        [NotificationLevel.Success]:  'snack-bar-success',
        [NotificationLevel.Warning]:  'snack-bar-warning',
        [NotificationLevel.Error]:    'snack-bar-error'
    };

const DURATION_MESSAGE = 7000; // 7 seconds
