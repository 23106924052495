import { Component } from '@angular/core';

import { UIViewModel } from './ui.view-model';
import { UIComponentBase } from './infrastructure/ui-component-base';

@Component({
    selector: 'zuni-ui',
    templateUrl: './ui.component.html',
    providers:
        [
            UIViewModel
        ]
})
export class UIComponent extends UIComponentBase
{
    //--------------------------------------------------------------------------------------------
    // CONSTRUCTOR
    //--------------------------------------------------------------------------------------------
    constructor(
        viewModel: UIViewModel
    )
    {
        super(viewModel);
    }

    //--------------------------------------------------------------------------------------------
    // COMMAND HANDLERS
    //--------------------------------------------------------------------------------------------
}
