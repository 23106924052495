import { Injectable } from '@angular/core';

import { SharedServicesProvider } from '../../core/customs/shared/shared-services-provider';
import { MainMenuItem } from '../../core/customs/shared/menus';
import { ViewModelBase } from '../infrastructure/view-model-base';

@Injectable()
export class ShellViewModel extends ViewModelBase
{
    //--------------------------------------------------------------------------------------------
    // CONSTRUCTOR
    //--------------------------------------------------------------------------------------------
    constructor(
        protected sharedServicesProvider: SharedServicesProvider,
    )
    {
        super(sharedServicesProvider);
    }

    //--------------------------------------------------------------------------------------------
    // PROPERTIES
    //--------------------------------------------------------------------------------------------
    
    //--------------------------------------------------------------------------------------------
    // OVERRIDE
    //--------------------------------------------------------------------------------------------
    public AfterViewModelInit()
    {
        super.AfterViewModelInit();
    }

    //--------------------------------------------------------------------------------------------
    // COMMAND HANDLERS
    //--------------------------------------------------------------------------------------------
    public OnSettingLanguage(lang: string)
    {
        this.SetLanguage(lang);
    }

    //--------------------------------------------------------------------------------------------
    // PRIVATE METHODS
    //--------------------------------------------------------------------------------------------
    private Navigate(href: string) {
        if(window.innerWidth <= 970)
        {
            this.sharedServicesProvider.document.getElementById('menu-btn').checked = false;
            this.sharedServicesProvider.document.getElementById('close-btn').checked = true;
        }

        this.NavigateTo([href]);
    }
}
