import { Routes } from '@angular/router';
import { FormType } from '../core/services/common/common.model';

export const UIRoutes: Routes =
[
    {
        path: '',
        data: { title: 'LABEL_SENSORY_MARKETING' },
        loadComponent: () => import('./home/home.component').then(m => m.HomeComponent)
    },
    {
        path: 'about-us',
        data: { title: 'LABEL_ABOUT_US' },
        loadComponent: () => import('./about-us/about-us.component').then(m => m.AboutUsComponent)
    },
    {
        path: 'experiences/scent',
        data: { title: 'LABEL_EXPERIENCE_SCENT' },
        loadComponent: () => import('./experiences/scent/scent.component').then(m => m.ScentExperienceComponent)
    },
    {
        path: 'experiences/audio',
        data: { title: 'LABEL_EXPERIENCE_AUDIO' },
        loadComponent: () => import('./experiences/audio/audio.component').then(m => m.AudioExperienceComponent)
    },
    {
        path: 'experiences/video',
        data: { title: 'LABEL_EXPERIENCE_VIDEO' },
        loadComponent: () => import('./experiences/video/video.component').then(m => m.VideoExperienceComponent)
    },
    {
        path: 'contact',
        data: { title: 'LABEL_CONTACT' },
        loadComponent: () => import('./contact/contact.component').then(m => m.ContactComponent)
    },
    {
        path: 'faq',
        data: { title: 'LABEL_FAQ' },
        loadComponent: () => import('./faq/faq.component').then(m => m.FAQComponent)
    },

    // // * forms
    // {
    //     path: 'music-request/:type',
    //     data: { title: 'Formulario de Solicitud Musical' },
    //     loadComponent: () => import('./forms/music-request/music-request.component')
    //         .then(m => m.MusicRequestComponent)
    // },

    // // * V2
    // {
    //     path: 'session',
    //     data: { title: 'LABEL_LOGIN_SIGNUP' },
    //     loadComponent: () => import('./session/session.component').then(m => m.SessionComponent)
    // },
    // {
    //     path: 'activate-user',
    //     data: { title: 'LABEL_USER_ACTIVATION' },
    //     loadComponent: () => import('./session/activate-user/activate-user.component').then(m => m.ActivateUserComponent)
    // },
    // {
    //     path: 'profile/:username',
    //     data: { title: 'LABEL_PROFILE' },
    //     loadComponent: () => import('./account/profile/profile.component').then(m => m.ProfileComponent)
    // },
];