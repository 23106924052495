import { Injectable, Inject } from '@angular/core';
import { Router, RouteReuseStrategy } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { DOCUMENT } from '@angular/common';

import { MessageNotifier } from './message-notifier';
import { SessionService } from '../../services/security/session/session.service';

@Injectable()
export class SharedServicesProvider
{
    //--------------------------------------------------------------------------------------------
    // CONSTRUCTOR
    //--------------------------------------------------------------------------------------------
    constructor(
        @Inject(DOCUMENT) public document: any,

        public router: Router,
        public messageNotifier: MessageNotifier,
        public componentReuseStrategy: RouteReuseStrategy,

        public _translateService: TranslateService,
        public _sessionService: SessionService,

        //public sessionState: SessionState,
        //public appState: AppState,
    )
    {
    }

    //--------------------------------------------------------------------------------------------
    // PROPERTIES
    //--------------------------------------------------------------------------------------------
    public hbSptScrAdded: boolean = false;
}
