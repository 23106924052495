import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';

export class ServiceBase
{
  constructor(protected Http : HttpClient)
  {
  }

  protected async Get(url: string, paramsObject: any | null = null): Promise<any>
  {
    if (paramsObject)
    {
      const params = Object.getOwnPropertyNames(paramsObject)
        .reduce((p, key) => p.set(key, paramsObject[key]), new HttpParams());

      return await firstValueFrom(this.Http.get(url, { params }));
    }

    return await firstValueFrom(this.Http.get(url));
  }

  protected async GetWithJWT(url: string, paramsObject: any | null = null, token: string): Promise<any>
  {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    });

    if (paramsObject)
    {
      const params = Object.getOwnPropertyNames(paramsObject)
        .reduce((p, key) => p.set(key, paramsObject[key]), new HttpParams());

      return await firstValueFrom(this.Http.get(url, { params, headers }));
    }

    return await firstValueFrom(this.Http.get(url, { headers }));
  }

  protected async Post(url : string, body : any | null = null) : Promise<any>
  {
    return await firstValueFrom(this.Http.post(url, body));
  }

  protected async PostWithJWT(url : string, body : any | null = null, token: string) : Promise<any>
  {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    });

    return await firstValueFrom(this.Http.post(url, body, { headers }));
  }

  protected async PostReturningEmptyResponse(url : string, body : any | null = null) : Promise<any>
  {
    return await firstValueFrom(this.Http.post(url, body, { responseType : 'text' }));
  }
}