import { Component } from '@angular/core';

import { ShellViewModel } from './shell.view-model';
import { UIComponentBase } from '../infrastructure/ui-component-base';
import { NavigationEnd, Router } from '@angular/router';

@Component(
    {
        selector: 'zuni-shell',
        templateUrl: './shell.component.html',
        styleUrls: ['./shell.component.css'],
        providers:
        [
            ShellViewModel
        ]
    }
)

export class ShellComponent extends UIComponentBase
{
    //--------------------------------------------------------------------------------------------
    // CONSTRUCTOR
    //--------------------------------------------------------------------------------------------
    constructor(
        viewModel: ShellViewModel,
        private router: Router
    )
    {
        super(viewModel);
    }

    //--------------------------------------------------------------------------------------------
    // PROPERTIES
    //--------------------------------------------------------------------------------------------

    //--------------------------------------------------------------------------------------------
    // COMMAND HANDLERS
    //--------------------------------------------------------------------------------------------
    ngOnInit(): void {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                window.scrollTo(0, 0);
            }
        });
    }
}
