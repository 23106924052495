import { Injectable } from "@angular/core";

import { ViewModelBase } from "./infrastructure/view-model-base";
import { SharedServicesProvider } from "../core/customs/shared/shared-services-provider";

@Injectable()
export class UIViewModel extends ViewModelBase
{
    //--------------------------------------------------------------------------------------------
    // CONSTRUCTOR
    //--------------------------------------------------------------------------------------------
    constructor(
        protected sharedServicesProvider: SharedServicesProvider,
    )
    {
        super(sharedServicesProvider);
        this.AllowAnonymous = true;
    }

    //--------------------------------------------------------------------------------------------
    // COMMAND HANDLERS
    //--------------------------------------------------------------------------------------------
}
